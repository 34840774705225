import React from 'react'

export const HrChart = () => {
  return (
    <div class="logo-holder">
      <div class="bg"></div>
      <div class="bar"></div>
      <div class="bar fill1"></div>
      <div class="bar fill2"></div>
      <div class="bar fill3"></div>
      <div class="bar fill4"></div>
      <div class="bar fill1"></div>
      <div class="bar fill5"></div>
      <div class="bar fill6"></div>
      <div class="bar"></div>
    </div>
  );
}
